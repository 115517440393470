//Colors
$orange: #ff7e00;
$light-orange: #fdad00;
$dark-orange: #f55d12;
$gray: #ededed;
$red: #ce5003;
$green: #7e9e39;
$blue: #0072a8;

$radius: 8px;
$small-radius: $radius / 2;
