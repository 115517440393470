.gallery {
  .gallery-item {
    display: block;
    height: rc(125);
    margin: rc(5);
    background: {
      position: center;
      size: cover; } } }


.featherlight {
  .featherlight-content {
    padding: rc(15 15 0);
    border-bottom-width: rc(15); }

  .featherlight-close-icon {
    width: rc(50);
    background: $white;
    color: $black;
    font-size: rc(30);
    line-height: rc(50);

    &:hover {
      background: $dark-orange;
      color: $white; } } }

.featherlight-next,
.featherlight-previous {
  top: calc(50% - #{rc(25)});
  right: 0;
  bottom: calc(50% - #{rc(25)});
  left: calc(100% - #{rc(50)});

  &:hover {
    background: $dark-orange; }

  span {
    font-size: rc(20); } }


.featherlight-previous {
  right: calc(100% - #{rc(50)});
  left: 0; }

