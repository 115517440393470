$global-font-size: 100% !default;
$rem-calc-in-px: true !default;

/// Removes the unit (e.g. px, em, rem) from a value, returning the number only.
///
/// @param {Number} $num - Number to strip unit from.
///
/// @returns {Number} The same number, sans unit.
@function rc-strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

/// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
/// @access private
///
/// @param {Number} $value - Pixel value to convert.
/// @param {Number} $base [null] - Base for pixel conversion.
///
/// @returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
@function -rc-to-rem($value, $base: null) {
  // Check if the value is a number
  @if type-of($value) != 'number' {
    @warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
    @return $value;
  }

  // Transform em into rem if someone hands over 'em's
  @if unit($value) == 'em' {
    $value: rc-strip-unit($value) * 1rem;
  }

  // Calculate rem if units for $value is not rem or em
  @if unit($value) != 'rem' {
    $value: rc-strip-unit($value) / rc-strip-unit($base) * 1rem;
  }

  // Turn 0rem into 0
  @if $value == 0rem {
    $value: 0;
  }

  @return $value;
}

/// Converts a pixel, percentage, rem or em value to a unitless value based on a given font size. Ideal for working out unitless line heights.
///
/// @param {Number} $value - Value to convert to a unitless line height
/// @param {Number} $base - The font size to use to work out the line height - defaults to $global-font-size
///
/// @return {Number} - Unitless number
@function rc-unitless-calc($value, $base: null) {

  // If no base is defined, defer to the global font size
  @if $base == null {
    $base: $global-font-size;
  }

  // First, lets convert our $base to pixels

  // If the base font size is a %, then multiply it by 16px
  @if unit($base) == '%' {
    $base: ($base / 100%) * 16px;
  }

  @if unit($base) == 'rem' {
    $base: rc-strip-unit($base) * 16px;
  }

  @if unit($base) == 'em' {
    $base: rc-strip-unit($base) * 16px;
  }

  // Now lets convert our value to pixels too
  @if unit($value) == '%' {
    $value: ($value / 100%) * $base;
  }

  @if unit($value) == 'rem' {
    $value: rc-strip-unit($value) * $base;
  }

  @if unit($value) == 'em' {
    $value: rc-strip-unit($value) * $base;
  }

  // 'px'
  @if unit($value) == 'px' {
    @return rc-strip-unit($value) / rc-strip-unit($base);
  }

  // assume that line-heights greatern then 10 are meant to be absolute in 'px'
  @if unitless($value) and ($value > 10) {
    @return $value / rc-strip-unit($base);
  }

  @return $value;
}

/// Converts one or more pixel values into matching rem values.
///
/// @param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.
/// @param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.
///
/// @returns {List} A list of converted values.
@function rc($values, $base: null) {
  $rem-values: ();
  $count: length($values);

  @if $rem-calc-in-px == true {
    @for $i from 1 through $count {
      @if type-of(nth($values, $i)) != 'number' {
        $rem-values: append($rem-values, nth($values, $i));
      } @else {
        $rem-values: append($rem-values, nth($values, $i) + px);
      }
    }
  } @else {
    // If no base is defined, defer to the global font size
    @if $base == null {
      $base: $global-font-size;
    }

    // If the base font size is a %, then multiply it by 16px
    // This is because 100% font size = 16px in most all browsers
    @if unit($base) == '%' {
      $base: ($base / 100%) * 16px;
    }

    // Using rem as base allows correct scaling
    @if unit($base) == 'rem' {
      $base: rc-strip-unit($base) * 16px;
    }

    @if $count == 1 {
      @return -rc-to-rem($values, $base);
    }

    @for $i from 1 through $count {
      $rem-values: append($rem-values, -rc-to-rem(nth($values, $i), $base));
    }

  }

  @return $rem-values;
}

// Converts a unitless, pixel, or rem value to em, for use in breakpoints.
@function -rc-bp-to-em($value) {
  // Pixel and unitless values are converted to rems
  @if unit($value) == 'px' or unitless($value) {
    $value: rc($value, $base: 16px);
  }

  // Then the value is converted to ems
  @return rc-strip-unit($value) * 1em;
}
