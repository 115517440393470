.box {
  @include aspect-ratio('.box-item', 2, 1);
  display: block;
  margin: rc(5);
  border: rc(1) solid darken($gray, 15%);

  &:before {
    transition: all 450ms ease-in-out; }

  &:hover,
  &.active {
    &.green {
      &:before {
        background: rgba($green, .75); } }

    &.apricot {
      &:before {
        background: rgba($dark-orange, .75); } }

    &.yellow {
      &:before {
        background: rgba($light-orange, .75); } }

    &.blue {
      &:before {
        background: rgba($blue, .75); } }

    &.orange {
      &:before {
        background: rgba($orange, .75); } }

    &.navy-blue {
      &:before {
        background: rgba(darken($blue, 20%), .75); } }

    &:before {
      background: rgba($gray, .75); }


    .box-item-title {
      color: $white; } }

  &-slim {
    @include aspect-ratio('.box-item', 5, 1); }

  &-item {
    &-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 450ms ease-in-out;
      font-weight: bold;
      text: {
        transform: uppercase;
        align: center; } } } }
