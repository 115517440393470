html {
  min-height: 100%; }

body {
  height: 100%;
  background: linear-gradient(to bottom, $light-orange 0%, $dark-orange 60%, $dark-orange 100%);
  font-family: Nunito, sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  margin-left: rc(10);
  color: $dark-orange;
  font: {
    weight: 900;
    family: Nunito, sans-serif; }
  text-transform: uppercase; }

.red {
  color: $red; }

.yellow {
  color: $light-orange; }

.green {
  color: $green; }

.orange {
  color: $orange; }

.apricot {
  color: $dark-orange; }

.blue {
  color: $blue; }

.navy-blue {
  color: darken($blue, 20%); }

.clean {
  margin: 0; }

.upper {
  text-transform: uppercase; }

.dot {
  list-style: none;

  li {
    position: relative;

    &:before {
      position: absolute;
      top: rc(10);
      left: rc(-15);
      width: rc(5);
      height: rc(5);
      border-radius: 50%;
      background: $orange;
      content: ''; } } }
