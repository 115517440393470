.slider {
  padding-bottom: rc(10);

  .image {
    display: block !important; //sass-lint:disable-line no-important
    height: rc(200);
    transition: all 450ms ease-in-out;
    background: {
      position: center;
      size: cover; } } }

@include breakpoint(medium) {
  .slider {
    .image {
      height: rc(300); } } }

@include breakpoint(large) {
  .slider {
    .image {
      height: rc(400); } } }
