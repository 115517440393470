.menu {
  padding: 0 !important; //sass-lint:disable-line no-important
  background: $orange;
  text-align: left;

  ul {
    margin: 0;
    padding: 0;
    list-style: none; }

  li {
    border-bottom: rc(1) solid $light-orange;

    &:last-of-type {
      border-bottom: 0; } }

  a {
    display: block;
    padding: rc(7 10);
    color: $white;
    font-weight: bold;
    text-transform: uppercase;

    &.active,
    &:hover {
      background: $gray;
      color: $black; } } }
