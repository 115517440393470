.header {
  position: relative;
  text-transform: uppercase;

  .site-name {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: rc(20);
    transform: translate(-50%, -50%);
    transition: all 400ms ease-in-out;
    background: rgba($dark-orange, .75);
    color: $white;
    letter-spacing: -2.5px;
    z-index: 10;

    p {
      margin: 0;
      border-bottom: rc(1) solid $white;
      font: {
        size: rc(30);
        weight: 200; }
      line-height: 1;
      text-align: center; }

    span {
      font-weight: bold; }

    .slogan {
      margin-top: rc(5);
      font-size: rc(14);
      letter-spacing: -.25px;
      text-align: center; } } }

@include breakpoint(medium) {
  .header {
    .site-name {
      padding: rc(25 35);

      p {
        font-size: rc(50); }

      .slogan {
        font-size: rc(16); } } } }

@include breakpoint(large) {
  .header {
    .site-name {
      &:hover {
        transform: translate(-50%, -50%) scale(1.5); } } } }
