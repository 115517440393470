//sass-lint:disable no-ids
#google-map {
  width: 100%;
  height: rc(200); }

@include breakpoint(medium) {
  #google-map {
    height: rc(300); } }

@include breakpoint(large) {
  #google-map {
    height: rc(400); } }
