.footer {
  padding-top: rc(10);
  background: $white;
  font-size: rc(12);
  text-align: center; }

@include breakpoint(medium) {
  .footer {
    &-left {
      text-align: left; }

    &-right {
      text-align: right; } } }
