.heading {
  position: relative;
  margin-left: rc(10);
  color: darken($gray, 15%);
  font: {
    size: rc(30);
    weight: bold;
    family: Nunito, sans-serif; }
  text-transform: uppercase;

  span {
    position: absolute;
    bottom: 0;
    left: 0;
    color: darken($gray, 65%);
    font: {
      size: rc(20);
      weight: 900; } } }

@include breakpoint(medium) {
  .heading {
    font-size: rc(40);

    span {
      font-size: rc(25); } } }
